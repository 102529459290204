import moment from 'moment';
import _get from 'lodash/get';

export const initialValuesSanitizer = props => {
  const { debtor, cosigner, cosignerContract } = props;
  const generalData = {
    promissoryType: undefined,
    promissory: undefined,
    saleNumber: '',
    isInvoiceHolder: false,
    requiresCosigner: true
  };

  const debtorData = debtor
    ? {
      newName: _get(debtor, 'newName', ''),
      newLastname: _get(debtor, 'newLastname', ''),
      debtorName: _get(debtor, 'name', ''),
      debtorLastname: _get(debtor, 'last_name', ''),
      debtorMaritalStatus: _get(debtor, 'marital_status', ''),
      debtorDoctype: _get(debtor, 'debtor_doctype_id', ''),
      debtorDocid: _get(debtor, 'identification', ''),
      debtorDocExpeditionLocality: _get(debtor, 'id_expedition_place', ''),
      debtorDocExpeditionDate: _get(debtor, 'id_expedition_date', null)
        ? debtor.type_of_identification == 'Cédula de Extranjería'
          ? _get(debtor, 'id_expedition_date', null)
          : moment(debtor.id_expedition_date, 'DD-MM-YYYY').format('YYYY/MM/DD')
        : null,
      debtorDocBirthdate: debtor.date_of_birth || null,
      debtorGender: _get(debtor, 'gender', ''),
      debtorFormationLevel: _get(debtor, 'level_of_studies', ''),
      debtorEmail: _get(debtor, 'email', ''),
      debtorPhone: _get(debtor, 'phone', ''),
      debtorCellphone: _get(debtor, 'mobile', ''),
      debtorNeighbourhood: _get(debtor, 'debtorNeighbourhood', ''),
      debtorNeighbourhoodId: _get(debtor, 'debtorNeighbourhoodId', 0),
      debtorAddress: _get(debtor, 'address', ''),
      debtorHouseType: _get(debtor, 'type_of_house', ''),
      debtorProfession: _get(debtor, 'profession.id', ''),
      debtorProfessionLabel: _get(debtor, 'profession.name', ''),
      debtorOccupation: _get(debtor, 'occupation', ''),
      debtorCompany: _get(debtor, 'company_name', ''),
      bankName: _get(
        debtor,
        'bank_name',
        ''
      ),
      bankAccountNumber: _get(
        debtor,
        'bank_account_number',
        ''
      ),
      bankAccountType: _get(
        debtor,
        'bank_account_type',
        ''
      ),
      debtorBirthPlace: _get(
        debtor,
        'birth_place',
        ''
      )
    }
    : {};

  const cosignerData = cosigner
    ? {
      cosignerContract: cosignerContract || null,
      cosignerName: _get(cosigner, 'name', ''),
      cosignerLastname: _get(cosigner, 'last_name', ''),
      cosignerMaritalStatus: _get(cosigner, 'marital_status', ''),
      cosignerDoctype: _get(cosigner, 'cosigner_doctype_id', ''),
      cosignerDocid: _get(cosigner, 'identification', ''),
      cosignerDocExpeditionLocality: _get(
        cosigner,
        'id_expedition_place',
        ''
      ),
      cosignerDocExpeditionDate: _get(cosigner, 'id_expedition_date', '')
        ? cosigner.type_of_identification == 'Cédula de Extranjería'
          ? _get(cosigner, 'id_expedition_date', '')
          : moment(cosigner.id_expedition_date, 'DD-MM-YYYY').format('YYYY/MM/DD')
        : null,
      cosignerGender: cosigner.gender || '',
      cosignerFormationLevel: cosigner.level_of_studies || '',
      cosignerEmail: cosigner.email || '',
      cosignerPhone: cosigner.phone || '',
      cosignerCellphone: cosigner.mobile || '',
      cosignerNeighbourhood: cosigner.cosignerNeighbourhood,
      cosignerNeighbourhoodId: _get(cosigner, 'cosignerNeighbourhoodId', 0),
      cosignerAddress: cosigner.address || '',
      cosignerHouseType: cosigner.type_of_house || '',
      cosignerProfession: _get(cosigner, 'profession.id', ''),
      cosignerProfessionLabel: _get(cosigner, 'profession.name', ''),
      cosignerOccupation: cosigner.occupation || '',
      cosignerCompany: cosigner.company_name || '',
      cosignerStratum: _get(cosigner, 'stratum', '')
    }
    : {};

  return { ...generalData, ...debtorData, ...cosignerData };
};

export const submitSanitizer = (values, props, getLocation) => {
  const {
    sale,
    first_invoice,
    second_invoice,
    invoiceValidationSkipped,
    articles,
    debtorContract,
    debtorStratum,
    uniquePromissory,
    debtor,
    cosigner,
    promissory,
    isTransfer,
    applyScoring,
    isDigitalSale,
    grace_period_deffered,
    studyLevels,
    scoringQuota,
    useMegaQuota,
    megaQuotaInfo,
    hasPets,
    isPromissoriesV2,
    externalProcessId,
    voucherSerial,
    unmaterializedVoucherProcessId,
    biometricLogId
  } = props

  const quotaValues = megaQuotaInfo ? megaQuotaInfo.filter(client => client !== null) : [];
  const minQuotaClient = quotaValues.reduce((min, client) => (client.quota < min.quota ? client : min), quotaValues[0]);
  const megacupoResponseId = minQuotaClient ? minQuotaClient.id : undefined;

  return {
    sale,
    first_invoice,
    second_invoice,
    invoice_validation_skipped: invoiceValidationSkipped,
    articles,
    grace_period_deffered,
    promissory: {
      code:
        uniquePromissory && !isTransfer
          ? uniquePromissory.indexOf('D') >= 0
            ? uniquePromissory
            : Number.parseInt(uniquePromissory)
          : values.promissoryType === 'M'
            ? values.saleNumber
            : null,
      type_of_promissory:
        uniquePromissory && !isTransfer ? null : values.promissoryType,
      contract_id: debtorContract,
      unmaterialized_process_id: externalProcessId || unmaterializedVoucherProcessId
    },
    debtor: {
      name: values.newName,
      last_name: values.newLastname,
      type_of_identification: debtor.type_of_identification,
      identification: values.debtorDocid.toString(),
      date_of_birth:
        moment(values.debtorDocBirthdate, 'YYYY/MM/DD').format('YYYY-MM-DD') ||
        debtor.date_of_birth,
      gender: values.debtorGender === 1 ? 'F' : 'M',
      phone: values.debtorPhone,
      mobile: values.debtorCellphone,
      type_of_house: values.debtorHouseType,
      social_stratus: debtorStratum,
      id_expedition_date: moment(
        values.debtorDocExpeditionDate,
        'YYYY/MM/DD'
      ).format('DD-MM-YYYY'),
      id_expedition_place: values.debtorDocExpeditionLocality,
      birth_place: values.debtorBirthPlace,
      marital_status: values.debtorMaritalStatus,
      level_of_studies: studyLevels.find(
        o => o.id == values.debtorFormationLevel
      ).name,
      profession_id: Number.parseInt(values.debtorProfession),
      profession_name: isDigitalSale ? values.professionName : '',
      occupation: values.debtorOccupation,
      company_name: values.debtorCompany,
      company_address: null,
      email: values.debtorEmail,
      bank_name: values.bankName,
      bank_account_number: values.bankAccountNumber,
      bank_account_type: values.bankAccountType,
      mobile_confirmed_at: values.debtorCellphoneConfirmedAt ? values.debtorCellphoneConfirmedAt : null,
    },
    debtor_address: {
      body: values.debtorAddress,
      location: isDigitalSale
        ? _get(props, 'debtor.locality', '')
        : _get(props, 'locality', ''),
      department: isDigitalSale
        ? _get(props, 'debtor.department', '')
        : _get(props, 'department', ''),
      neighborhood: promissory
        ? values.debtorNeighbourhood
        : _get(props, 'debtor.debtorNeighbourhood', '')
    },
    cosigner: values.cosignerDocid
      ? {
        name: values.cosignerName,
        last_name: values.cosignerLastname,
        type_of_identification: cosigner.type_of_identification,
        identification: values.cosignerDocid,
        date_of_birth: cosigner.date_of_birth,
        gender: values.cosignerGender === 1 ? 'F' : 'M',
        phone: values.cosignerPhone,
        mobile: values.cosignerCellphone,
        type_of_house: values.cosignerHouseType,
        social_stratus: values.cosignerStratum,
        id_expedition_date: moment(
          values.cosignerDocExpeditionDate,
          'YYYY/MM/DD'
        ).format('DD-MM-YYYY'),
        id_expedition_place: values.cosignerDocExpeditionLocality,
        marital_status: values.cosignerMaritalStatus,
        level_of_studies: studyLevels.find(
          o => o.id == values.cosignerFormationLevel
        ).name,
        profession_id: Number.parseInt(values.cosignerProfession),
        occupation: values.cosignerOccupation,
        company_name: values.cosignerCompany,
        company_address: null,
        contract_id: values.cosignerContract || null,
        email: values.cosignerEmail
      }
      : null,
    cosigner_address: {
      body: values.cosignerAddress,
      location: isDigitalSale
        ? _get(props, 'cosigner.locality', '')
        : _get(props, 'locality', ''),
      department: isDigitalSale
        ? _get(props, 'cosigner.department', '')
        : _get(props, 'department', ''),
      neighborhood: promissory
        ? values.cosignerNeighbourhood
        : getLocation(
          values,
          'cosignerNeighbourhood',
          'description',
          'neighbourhoodNames'
        )
    },
    useScoring: applyScoring,
    scoringQuota: scoringQuota,
    megacupoResponseId,
    useMegaQuota,
    hasPets,
    is_promissories_v2: isPromissoriesV2,
    voucher_serial: voucherSerial,
    biometricLogId
  };
};

export const submitOtpSanitizerVoucher = (documentType, props) => {
  const {
    externalProcessId,
    saleDetails,
    process,
    isRemoteTeleshopping,
    quotaValue,
    promissory
  } = props;
  return {
    externalProcessId,
    code: promissory.code,
    documentType,
    totalValue: saleDetails.articles.reduce(
      (accumulator, item) => accumulator + item.value * item.amount * (process === 'remoteTeleshopping' ? item.num_monthly_instalments : 1),
      0
    ),
    downPayment: saleDetails.downPayment,
    pointOfSaleId: saleDetails.pointOfSaleID,
    salesmanId: saleDetails.salesmanId,
    articles: saleDetails.articles,
    isRemoteTeleshopping,
    quotaValue
  };
};

export const submitOtpSanitizerPromissory = (documentType, props) => {
  const {
    promissoryId,
    unmaterializedPromissoryProcessId,
  } = props;
  return {
    externalProcessId: unmaterializedPromissoryProcessId,
    promissoryId,
    documentType,
  };
};
